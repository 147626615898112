@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_light-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_light_italic-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_light_italic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_italic-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_medium-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_medium_italic-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_medium_italic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_bold-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_bold_italic-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_bold_italic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_heavy-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_heavy-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('assets/fonts/GillSans/gill_sans_heavy_italic-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gill_sans_heavy_italic-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans Condensed';
    src: url('assets/fonts/GillSans/gillsans_condensed-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gillsans_condensed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans Condensed';
    src: url('assets/fonts/GillSans/gillsans_condensed_bold-webfont.woff2') format('woff2'),
    url('assets/fonts/GillSans/gillsans_condensed_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 100;
    src: url("assets/fonts/ProximaNova/proximanova-light-webfont.eot");
    src: url("assets/fonts/ProximaNova/proximanova-light-webfont.eot?iefix") format("embedded-opentype"),
    url("assets/fonts/ProximaNova/proximanova-light-webfont.woff") format("woff"),
    url("assets/fonts/ProximaNova/proximanova-light-webfont.ttf") format("truetype"),
    url("assets/fonts/ProximaNova/proximanova-light-webfont.svg") format("svg");
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 400;
    src: url("assets/fonts/ProximaNova/proximanova-regular-webfont.eot");
    src: url("assets/fonts/ProximaNova/proximanova-regular-webfont.eot?iefix") format("embedded-opentype"),
    url("assets/fonts/ProximaNova/proximanova-regular-webfont.woff") format("woff"),
    url("assets/fonts/ProximaNova/proximanova-regular-webfont.ttf") format("truetype"),
    url("assets/fonts/ProximaNova/proximanova-regular-webfont.svg") format("svg");
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 500;
    src: url("assets/fonts/ProximaNova/proximanova-semibold-webfont.eot");
    src: url("assets/fonts/ProximaNova/proximanova-semibold-webfont.eot?iefix") format("embedded-opentype"),
    url("assets/fonts/ProximaNova/proximanova-semibold-webfont.woff") format("woff"),
    url("assets/fonts/ProximaNova/proximanova-semibold-webfont.ttf") format("truetype"),
    url("assets/fonts/ProximaNova/proximanova-semibold-webfont.svg") format("svg");
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 700;
    src: url("assets/fonts/ProximaNova/proximanova-bold-webfont.eot");
    src: url("assets/fonts/ProximaNova/proximanova-bold-webfont.eot?iefix") format("embedded-opentype"),
    url("assets/fonts/ProximaNova/proximanova-bold-webfont.woff") format("woff"),
    url("assets/fonts/ProximaNova/proximanova-bold-webfont.ttf") format("truetype"),
    url("assets/fonts/ProximaNova/proximanova-bold-webfont.svg") format("svg");
}

@font-face {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 700;
    src: url("assets/fonts/ProximaNova/proximanova-bold-webfont.eot");
    src: url("assets/fonts/ProximaNova/proximanova-bold-webfont.eot?iefix") format("embedded-opentype"),
    url("assets/fonts/ProximaNova/proximanova-bold-webfont.woff") format("woff"),
    url("assets/fonts/ProximaNova/proximanova-bold-webfont.ttf") format("truetype"),
    url("assets/fonts/ProximaNova/proximanova-bold-webfont.svg") format("svg");
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Bold.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Bold.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Bold.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Bold.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BlackItalic.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BlackItalic.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BlackItalic.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BlackItalic.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BlackItalic.svg#BrandonGrotesque-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BoldItalic.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BoldItalic.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BoldItalic.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-BoldItalic.svg#BrandonGrotesque-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Regular.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Regular.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Regular.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Regular.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Thin.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Thin.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Thin.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Thin.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Thin.svg#BrandonGrotesque-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Medium.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Medium.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Medium.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Medium.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Light.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Light.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Light.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Light.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-LightItalic.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-LightItalic.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-LightItalic.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-LightItalic.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-LightItalic.svg#BrandonGrotesque-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-RegularItalic.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-RegularItalic.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-RegularItalic.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-RegularItalic.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-RegularItalic.svg#BrandonGrotesque-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-MediumItalic.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-MediumItalic.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-MediumItalic.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-MediumItalic.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-MediumItalic.svg#BrandonGrotesque-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Black.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Black.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Black.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Black.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-ThinItalic.eot');
    src: url('assets/fonts/Brandon Grotesque/BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-ThinItalic.woff2') format('woff2'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-ThinItalic.woff') format('woff'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-ThinItalic.ttf') format('truetype'),
    url('assets/fonts/Brandon Grotesque/BrandonGrotesque-ThinItalic.svg#BrandonGrotesque-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
