@import "_fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/*html {
  scroll-behavior: auto;
}*/

body {
  margin: 0;
  font-family: 'ProximaNova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.process-slider {
  overflow: visible !important;
}

.hero-body, .hero-brows, .hero-cheek, .hero-crown, .hero-ears, .hero-eyes,
.hero-high-cheek, .hero-glasses, .hero-forehead, .hero-neck,
.hero-face, .hero-space {
  @apply stroke-gray-500 fill-none
}

.animate-delay-10 {
  animation-delay: 10s !important;
}

.animate-delay-5 {
  animation-delay: 5s !important;
}

.custom-circle-text {
  shape-outside: circle(50%);
  clip-path: circle(50%);
  display: block;
  width: 90%;
  max-width: 90%;
  text-align: center;
  margin: auto;
}
/*
.hero-un {
  @apply stroke-none fill-none
}

.hero-un-2 {
  @apply stroke-none fill-none
}

.hero-none {
  @apply stroke-none fill-none
}

.hero-crown {
@apply stroke-red-500 fill-red-300
}

.hero-face {
  @apply stroke-purple-500 fill-purple-200
}

.hero-space {
  @apply stroke-green-900 fill-green-500
}

.hero-ears {
  @apply stroke-yellow-500 fill-yellow-200
}

.hero-brows {
  @apply stroke-red-800 fill-red-600
}

.hero-high-cheek {
  @apply stroke-orange-800 fill-orange-600
}

.hero-eyes {
  @apply stroke-blue-800 fill-blue-600
}

.hero-glasses {
  @apply stroke-purple-800 fill-purple-600
}

.hero-cheek {
  @apply stroke-orange-500 fill-orange-200
}

.hero-forehead {
  @apply stroke-teal-500 fill-teal-200
}

.hero-neck {
  @apply stroke-blue-500 fill-blue-300;
}

.hero-body {
  @apply stroke-green-500 fill-green-300/20;
}*/

.about-hero-svg{
  /*fill:none;
  stroke:#231f20;*/
  stroke-miterlimit:10;
  stroke-width:.5px;
  @apply transition;
  /*@apply stroke-gray-500;*/
  @apply group-hover:translate-x-0 group-hover:translate-y-0
}

/* Customize arrow color, background, and size */
.process-slider .swiper-button-prev,
.process-slider .swiper-button-next {
  color: white; /* Change arrow color */
  opacity: 50%;
}

.process-slider .swiper-button-prev:hover,
.process-slider .swiper-button-next:hover {
  opacity: 100%;
}

/* styles.css */
.process-slider .swiper-slide {
  @apply opacity-90 transition;
}

.process-slider .swiper-slide.swiper-slide-active {
  @apply opacity-100 -translate-y-3;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-heading {
  @apply text-4xl sm:text-6xl md:text-7xl leading-tight;
}

.section-body {
  @apply font-body text-xl sm:text-2xl md:text-3xl font-medium;
}

.section-container {
  @apply max-w-10xl py-10 px-6 sm:px-16 xl:px-20 m-auto;
}

.footer-container {
  @apply max-w-10xl py-24 lg:py-32 px-12 sm:px-16 xl:px-20 m-auto;
}

.footer-body {
  @apply font-body text-xl lg:text-2xl font-medium;
}